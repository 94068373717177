
import { defineComponent, reactive, computed } from "vue"
import CustomCheckbox from "./CustomCheckbox.vue"

export default defineComponent({
    components: {
        CustomCheckbox,
    },
    emits: ['search-item'],
    setup(props, ctx) {
        const mainQueryFormat = {
            dubCr: "filter[dubCr]",
            bedBound: "filter[bedBound]",
            ctDriver: "filter[ctDriver]",
            ctLanguage: "filter[ctLanguage]",
            nightService: "filter[nightService]",
            mobilityAssist: "filter[mobilityAssist]",
            budgetInt: "filter[budgetInt]"
        }

        const mainQuery = computed(() => {
            let query;

            const activeQueries = Object.entries(checkBoxes).filter(itm => itm[1]).map(itm => itm[0])

            // query = activeQueries.filter(itm => mainQueryFormat.hasOwnProperty(itm)).map(itm => [mainQueryFormat[itm], formData[itm]])
            query = activeQueries.filter(itm => mainQueryFormat.hasOwnProperty(itm)).map(itm => [itm, formData[itm]])

            return query.length ? Object.fromEntries(query) : null;
        })

        const zipCodeQuery = computed(() => {
            if (checkBoxes.zipCode) {
                // return { 'filter[zipCode.regex]': `^${formData.zipCode}` }
                return { zipCode: formData.zipCode }
            }

            return null
        })

        const startDateQuery = computed(() => {
            if (checkBoxes.startDate) {
                return { arrivalDate: formData.startDate }
            }
            return null
        })

        const householdOpt = [
            { text: 'Ja', value: 'Ja' },
            { text: 'nur Hauswirtschaft', value: 'nur Hauswirtschaft' },
            { text: 'Nein', value: 'Nein' },
            { text: 'keine Angabe', value: null }
        ]

        const bedBoundOpt = [
            { text: 'Ja', value: 'Ja' },
            { text: 'Nein', value: 'Nein' },
            { text: 'keine Angabe', value: null }
        ]

        const driverLicenseOpt = [
            { text: 'Ja', value: 'Ja' },
            { text: 'nicht relevant', value: 'nicht relevant' },
            { text: 'wünschenswert', value: 'wünschenswert' },
            { text: 'keine Angabe', value: null }
        ]

        const languageProficiencyOpt = [
            { text: "sehr gut", value: "sehr gut" },
            { text: "gut", value: "gut" },
            { text: "befriedigend", value: "befriedigend" },
            { text: "ausreichend", value: "ausreichend" },
            { text: "sehr gering", value: "sehr gering" },
            { text: "keine", value: "keine" }
        ]

        const nightServiceOpt = [
            { text: "täglich", value: "täglich" },
            { text: "gelegentlich", value: "gelegentlich" },
            { text: "nie", value: "nie" },
            { text: "keine Angabe", value: null }
        ]

        const mobilityAssistOpt = [
            { text: "vollumfänglich", value: "vollumfänglich" },
            { text: "Unterstützung", value: "Unterstützung" },
            { text: "Nein", value: "Nein" },
            { text: "keine Angabe", value: null }
        ]


        const checkBoxes = reactive({
            dubCr: false,
            bedBound: false,
            ctDriver: false,
            ctLanguage: false,
            zipCode: false,
            startDate: false,
            nightService: false,
            mobilityAssist: false,
            budgetInt: false
        })

        const isSearchable = computed(() => true || Object.values(checkBoxes).some(v => v))

        const formData = reactive({
            dubCr: householdOpt[0].value,
            bedBound: bedBoundOpt[0].value,
            ctDriver: driverLicenseOpt[0].value,
            ctLanguage: languageProficiencyOpt[0].value,
            zipCode: "",
            startDate: new Date().toISOString().split("T")[0],
            nightService: nightServiceOpt[0].value,
            mobilityAssist: mobilityAssistOpt[0].value,
            budgetInt: 0
        })


        const zipFormatter = (value) => {
            if (value && isNaN(value.at(-1))) {
                return value.slice(0, -1)
            }
            return value
        }

        const handleSubmit = () => {
            const queries = {
                ...mainQuery.value,
                ...zipCodeQuery.value,
                ...startDateQuery.value
            }

            ctx.emit('search-item', queries)
        }

        return {
            mainQuery,
            zipCodeQuery,
            startDateQuery,
            checkBoxes,
            formData,
            householdOpt,
            bedBoundOpt,
            driverLicenseOpt,
            languageProficiencyOpt,
            nightServiceOpt,
            mobilityAssistOpt,
            zipFormatter,
            handleSubmit,
            isSearchable
        }
    }
})

